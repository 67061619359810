<script>
  import Jumbotron from './Jumbotron.svelte'
  import Year2023 from './Year2023.svelte'
  import Year2022 from './Year2022.svelte'
  import Year2021 from './Year2021.svelte'
  import Year2020 from './Year2020.svelte'
  import Year2019 from './Year2019.svelte'
  import Year2018 from './Year2018.svelte'
  import LongTimeAgo from './LongTimeAgo.svelte'
  import Profile from './Profile.svelte'
  import Tabs from './Tabs.svelte'

  let tab = '2022'

  function updateCurrentTab(e) {
    tab = e.detail
  }
</script>

<svelte:head>
  <link
    rel="stylesheet"
    href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
    integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
    crossorigin="anonymous"
  />
</svelte:head>

<div class="container">
  <Jumbotron />

  <h1>個人資料</h1>
  <Profile />

  <h1>參與作品</h1>
  <Tabs current={tab} on:tab-click={updateCurrentTab} />

  {#if tab == '2023'}
    <Year2023 />
  {/if}
  {#if tab == '2022'}
    <Year2022 />
  {/if}
  {#if tab == '2021'}
    <Year2021 />
  {/if}
  {#if tab == '2020'}
    <Year2020 />
  {/if}
  {#if tab == '2019'}
    <Year2019 />
  {/if}
  {#if tab == '2018'}
    <Year2018 />
  {/if}
  {#if tab == 'longTimeAgo'}
    <LongTimeAgo />
  {/if}
</div>

<style>
  :global(ul) {
    margin: 0;
    padding: 0;
  }
  :global(ul > li > ul) {
    margin-left: 2em;
  }
</style>
