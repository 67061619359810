<script>
  import Work from './Work.svelte'
</script>

<Work
  name="Mercury Bot 自動放貸機器人"
  screenshots={['/images/mercury.png', '/images/bitfinex-result.png']}
>
  <ul>
    <li>
      自動在 Bitfinex 以設定好的策略放貸, 自動計算利率, 期限,
      自動在利率最好的時候 all in 資金, 年化報酬率可達 10% 以上.
    </li>
    <li>透過 Discord 與 Mercury 溝通可以設定策略與得知目前狀態.</li>
  </ul>
</Work>
