<script>
  import Work from './Work.svelte'
</script>

<Work
  name="東方雲夢閣 ～ The Hovering Castle on Cloud. (東方雲夢閣一)"
  url="https://1.thcc.tw"
  screenshots={['/images/thcc.png']}
>
  <ul>
    <li>業餘時間開發 2020-05 ~ 2020-08).</li>
    <li>
      後端使用
      <a href="http://rubyonrails.org/" target="_blank">Ruby on Rails</a>
      開發.
      <ul>
        <li>使用了 ActionCable 作為即時通訊手段.</li>
        <li>
          使用了
          <a href="https://github.com/ayamomiji/behave_fun">BehaveFun</a>
          實作戰鬥系統, 設計了專屬語言供設計師使用.
        </li>
      </ul>
    </li>
    <li>前端使用 Next.js 打造.</li>
  </ul>
  <p>除了相依套件以外, 幾乎所有的功能都是我自己寫的.</p>
</Work>

<Work name="BehaveFun" url="https://github.com/ayamomiji/behave_fun">
  <ul>
    <li>業餘時間開發 (2020-05).</li>
    <li>
      這裡有一些它的基礎介紹:
      <a href="http://ayaya.tw/posts/2020/behave-fun/"
        >BehaveFun: 使用 Ruby 實作的行為樹</a
      >
    </li>
  </ul>
</Work>

<Work
  name="接頭霸王"
  url="https://kyaru-concat.now.sh/"
  screenshots={['/images/kyaru-concat.png']}
>
  <ul>
    <li>將圖片和凱留的頭進行簡單合成的小工具.</li>
    <li>
      此小工具是為了嘗試
      <a href="https://zeit.co/" target="_blank">now</a>
      與 svelte + fabric.js 隨手製作, 費時僅數小時.
    </li>
    <li>
      在<a
        href="https://forum.gamer.com.tw/C.php?bsn=30861&snA=18856"
        target="_blank">巴哈姆特</a
      >獲得大量迴響, 但因垃圾回應過多導致版務出面鎖文.
    </li>
  </ul>
</Work>
