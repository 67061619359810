<script>
  import Work from './Work.svelte'
</script>

<Work
  name="夢見女僕咖啡館"
  url="https://yumemi.tw"
  screenshots={['/images/yumemi1.png', '/images/yumemi2.png']}
>
  <ul>
    <li>創立於 2021, 有實體店面的餐廳.</li>
    <li>
      後端使用
      <a href="https://rubyonrails.org/" target="_blank">Ruby on Rails</a>
      開發.
    </li>
    <li>
      使用網站處理員工打卡, 工時計算, 線上訂單, 薪資與分潤計算, 員工資料管理,
      半自動報班與排班系統, 以及簡單的首頁.
    </li>
    <li>因疫情與經濟壓力, 轉讓給他人營運.</li>
  </ul>
</Work>

<Work
  name="東方雲夢閣 ～ The Hovering Cloud Colosseum. (東方雲夢閣二)"
  url="https://thcc.tw"
>
  <ul>
    <li>業餘時間開發 (2021 ~ ).</li>
    <li>
      後端使用
      <a href="https://rubyonrails.org/" target="_blank">Ruby on Rails</a>
      開發.
      <ul>
        <li>
          使用了
          <a href="https://github.com/ayamomiji/behave_fun">BehaveFun</a>
          實作戰鬥系統, 設計了專屬語言供設計師使用.
        </li>
      </ul>
    </li>
    <li>前端使用 React, PIXI.js 打造, 並可作為 PWA 在手機上順利運作.</li>
    <li>
      可至 <a href="https://www.youtube.com/channel/UCITod30khzgX-f7drR0om4Q"
        >YouTube</a
      > 觀看展示影片.
    </li>
  </ul>
  <p>除了相依套件以外, 幾乎所有的功能都是我自己寫的.</p>
</Work>
