<script>
  import { createEventDispatcher } from 'svelte'

  export let current

  const dispatch = createEventDispatcher()
  const tabs = [
    { name: '2023', label: '2023' },
    { name: '2022', label: '2022' },
    { name: '2021', label: '2021' },
    { name: '2020', label: '2020' },
    { name: '2019', label: '2019' },
    { name: '2018', label: '2018' },
    { name: 'longTimeAgo', label: '更久以前' }
  ]
</script>

<ul class="nav nav-tabs my-3">
  {#each tabs as tab}
    <li class="nav-item">
      <button
        class="nav-link"
        class:active={current == tab.name}
        on:click={() => dispatch('tab-click', tab.name)}
      >
        {tab.label}
      </button>
    </li>
  {/each}
</ul>
