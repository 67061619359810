<script>
  import Work from './Work.svelte'
</script>

<Work
  name="OnePercent 小頻率與 Meemeeapp 小秘密"
  url="https://itunes.apple.com/tw/app/%E5%B0%8F%E9%A0%BB%E7%8E%87/id1401223421?mt=8"
  screenshots={['/images/one-percent-app.png']}
>
  <ul>
    <li>任職於優租網路科技股份有限公司時開發 (2018-07 ~ 2020-03)</li>
    <li>
      後端使用
      <a href="http://rubyonrails.org/" target="_blank">Ruby on Rails</a>
      開發.
      <ul>
        <li>使用了 ActionCable 作為即時通訊手段.</li>
        <li>
          以 GraphQL 作為 API 介面, 大幅減少了製作大量 REST API 的需求和時間,
          減少與客戶端的溝通成本.
          <a href="/images/graphql-example.png" target="_blank">範例</a>
          <a
            href="https://gist.github.com/ayamomiji/083c2e2c819e93cc761a484ebf8def16"
            target="_blank">部分程式範例</a
          >
        </li>
        <li>
          優化了
          <a
            href="https://github.com/ayamomiji/activestorage-aliyun"
            target="_blank">activestorage-aliyun</a
          >
          使其上傳大型檔案的效率增加了
          <a href="images/100x.png" target="_blank">一百倍</a>
        </li>
      </ul>
    </li>
    <li>
      App 使用 React Native 開發.
      <ul>
        <li>
          從零開始, 在幾乎沒有前輩帶領的情況下, 學習使用 React Native
          開發手機應用程式, 在兩個月內連同伺服器端完成許多功能,
          並且與現有網頁版本無縫互動. 以下擷取部分範例:
          <ul>
            <li>
              <a href="/images/op-examples/waiting-promise.gif" target="_blank"
                >等待檔案上傳</a
              >
              點擊儲存後, 上傳完畢才會跳轉頁面, 使用 async/await 與 Promise 實作.
            </li>
            <li>
              <a href="/images/op-examples/chatroom-photo.gif" target="_blank"
                >上傳照片</a
              >
            </li>
            <li>
              <a
                href="/images/op-examples/mission-message-style.gif"
                target="_blank">接收並完成任務</a
              >
            </li>
            <li>
              <a
                href="/images/op-examples/input-to-change-flag.gif"
                target="_blank">國碼輸入方塊</a
              >
              可點擊選擇或直接輸入.
            </li>
            <li>
              <a
                href="/images/op-examples/sign-up-flow-with-phone-verification.gif"
                target="_blank">電話驗證流程.</a
              >
            </li>
            <li>
              <a href="/images/op-examples/tappay-webview.gif" target="_blank"
                >透過 TapPay 付款</a
              >
              由於 TapPay SDK 只有網頁版與 iOS/Android 原生版, 因此使用 WebView,
              打開的 modal 中其實是一個 WebView.
            </li>
            <li>
              <a href="/images/op-examples/endless-paging.gif" target="_blank"
                >Endless Paging</a
              >
              向下滾動會載入更多資料.
            </li>
            <li>
              <a
                href="/images/op-examples/updating-unread-count.gif"
                target="_blank">未讀數量更新</a
              >
              透過 GraphQL Subscription 達成.
            </li>
          </ul>
        </li>
        <li>
          使用
          <a href="https://www.apollographql.com/" target="_blank"
            >Apollo Client</a
          >
          執行 GraphQL 請求.
        </li>
        <li>透過 GraphQL Subscription 機制即時更新資訊.</li>
      </ul>
    </li>
  </ul>
</Work>
