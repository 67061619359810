<script>
  export let name
  export let url
  export let nsfw
  export let screenshots = []
</script>

<div class="row my-3">
  <div class="col">
    <h3>
      {#if url}<a href={url} target="_blank">{name}</a>{:else}{name}{/if}
      {#if nsfw}<span class="text-muted ml-1">(NSFW)</span>{/if}
    </h3>
    <slot />
  </div>

  {#each screenshots as screenshot}
    <div class="col-sm-4 d-sm-block">
      <img src={screenshot} alt={name} class="mw-100" />
    </div>
  {/each}
</div>
