<script>
  import Work from './Work.svelte'
</script>

<Work
  name="ayaya 的書籤樹"
  url="https://github.com/ayamomiji/bookmark-tree"
  screenshots={['/images/bookmarktree.png']}
>
  <ul>
    <li>業餘時開發 (2011/03 起).</li>
    <li>
      主要使用
      <a href="https://svelte.dev/" target="_blank">Svelte</a>
      開發.
    </li>
  </ul>
  <p>這是我製作的第一個 Google Chrome 的擴充套件.</p>
  <p>平均評分: 4.52 顆星 (超讚)</p>
  <p>
    <del
      >雖然很久沒更新了, 不過由於 Google 抱怨我使用了舊的擴充套件架構,
      將來有可能被下架.</del
    >
  </p>
  <p>
    <del
      >2019/11/25 更新: 目前已經被下架了, 不過似乎還是<a
        href="https://www.reddit.com/r/chromeextensions/comments/8na7c0/cant_use_ayayas_bookmark_tree_anymore/"
        target="_blank">一直有些人在持續使用中</a
      >, 會考慮找點時間重製它.</del
    >
  </p>
  <p>2019/11/26 更新: 使用 Svelte 重製並且重新上架了.</p>
</Work>

<Work
  name="我印與噗印"
  url="http://commandp.com"
  screenshots={['/images/commandp.png']}
>
  <ul>
    <li>任職於我印網路科技股份有限公司時開發 (2014/10 ~ 2016/02).</li>
    <li>
      後端採用 Ruby on Rails, 噗印前端採用 node.js 與 React 設計為 Universal
      JavaScript 架構.
    </li>
  </ul>
  <p>任職期間開發許多後端系統:</p>
  <ul>
    <li>製圖: 使用 ImageMagick 合成來自前端編輯器提供的各種圖片資訊.</li>
    <li>
      印刷拼版: 使用 Illustrator Script 輸出指定格式之 AI/PDF
      檔案並傳輸給工廠印刷用.
    </li>
    <li>
      價格計算與折價系統:
      設計了可針對特定商品/特定類型/折價門檻/折扣百分比或固定值/日期限定的折價系統,
      並且可設定一次即套用在多過貨幣上.
    </li>
    <li>
      金流串接: 串接了 PayPal, 藍新 (信用卡/非信用卡系列) 等,
      並且能夠在後台追蹤付款狀態.
    </li>
    <li>API 架構設計: 統一一致的回應格式與錯誤處理.</li>
  </ul>
  <p>任職期間開發許多前端系統:</p>
  <ul>
    <li>
      視覺化編輯器:
      讓使用者可以用簡單的拖拉方式完成圖片的合成並且將結果上傳到後端伺服器.
    </li>
    <li>
      複雜的後台功能: 利用 React/Flux 架構, 讓後台不再只是簡單的 Scaffold CRUD,
      可以更針對實際需求為管理者設計更好用的界面.
    </li>
    <li>
      Universal JavaScript 架構: 讓前端可以以非常快的速度回應,
      並且一致化前端的開發 (前端工程師再也不需要配合 Rails 的慣例了),
      後端僅需要透過 API 提供資料.
    </li>
  </ul>
</Work>

<Work name="KamiGami 遊戲引擎 (未上線)">
  <ul>
    <li>任職於百臂網路科技股份有限公司時開發 (2013/02 起).</li>
    <li>
      本引擎包含了各個平台的執行環境, 我負責的部份為瀏覽器端, 使用 CoffeeScript
      語言.
    </li>
  </ul>
  <p>這是第一次使用 HTML5 Canvas.</p>
  <p>
    原先使用 JavaScript 直接寫, 但因為越來越複雜, 且沒有單元測試以及打包系統,
    因此改以 CoffeeScript 進行重構並加上單元測試.
  </p>
  <p>
    單元測試與打包的部份使用
    <a href="http://gruntjs.com/" target="_blank">grunt</a>
    進行.
  </p>
</Work>

<Work
  name="KamiGami 商城 (未上線)"
  screenshots={[
    '/images/kamigami-store.png',
    '/images/kamigami-store-admin.png'
  ]}
>
  <ul>
    <li>任職於百臂網路科技股份有限公司時開發 (2013/02 起).</li>
    <li>
      使用
      <a href="http://rubyonrails.org/" target="_blank">Ruby on Rails</a>
      開發.
    </li>
  </ul>
  <p>開始嘗試設計 Responsive 的網頁.</p>
  <p>
    後台是使用
    <a href="http://angularjs.org/" target="_blank">AngularJS</a>
    開發的.
  </p>
</Work>

<Work
  name="LOCOMO 運動記錄"
  url="http://locomo.passionbean.com/"
  screenshots={['/images/locomo.png']}
>
  <ul>
    <li>任職於熱情豆行動樂活科技股份有限公司時開發 (2012/02 起).</li>
    <li>
      使用
      <a href="http://rubyonrails.org/" target="_blank">Ruby on Rails</a>
      開發.
    </li>
  </ul>
  <p>在這裡首次嘗試了服務導向架構 (SOA) 的開發, 將會員資料獨立存放.</p>
  <p>開發過的東西包含了一系列供手機應用程式存取的 API, 以及網站大部份的功能:</p>
  <ul>
    <li>活動賽事列表/搜尋</li>
    <li>運動記錄的地圖繪製以及數據分析</li>
    <li>好友/通知/按讚/留言/動態等類似 Facebook 的社群系統</li>
  </ul>
  <p>可惜這些線上的功能目前都被拔掉了... :(</p>
</Work>
